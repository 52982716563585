// 需求
import request from '@/utils/request1'
import { transformRequirement, restoreRequirement } from '@/utils/transform/requirement.js'
import { restoreSupply, transformSupply } from '@/utils/transform/supply.js'

// 需求列表 ===============================================

// 获取推荐需求列表
export function getRecommendList(params) {
    return request.get(`/recommend/requirements`, { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformRequirement)
        }
        return Promise.resolve(temp)
    })
}
// 获取需求列表
export function getRequirementList(params) {
    return request.get('/requirements', { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformRequirement)
        }
        return Promise.resolve(temp)
    })
}
// 获取后台需求发布审核列表
export function getOpRequirementList(params) {
    return request.get('/op/requirements', { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformRequirement)
        }
        return Promise.resolve(temp)
    })
}
// 获取当前专家需求发布审核列表
export function getExpertRequirementList(params) {
    return request.get('/user/requirements', { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformRequirement)
        }
        return Promise.resolve(temp)
    })
}
// 获取需求对接列表
export function getPubRequirementList(params) {
    return request.get(`/op/pub/requirements`, { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformRequirement)
        }
        return Promise.resolve(temp)
    })
}
// 获取当前企业需求列表
export function getCompanyRequirementList(params) {
    return request.get(`/user/company/requirements`, { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformRequirement)
        }
        return Promise.resolve(temp)
    })
}
// 获取需求响应列表
export function getRequirementSupplyList(id) {
    return request.get(`/requirements/${id}/supplys`).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformSupply)
        }

        return Promise.resolve(temp)
    })
}
// 获取评价列表
export function getReviewList(id) {
    return request.get(`/requirements/${id}/reviews`)
}

// 需求详情 ===============================================

// 获取需求详情
export function getRequirement(id) {
    return request.get(`/requirements/${id}`).then(({ data = {} }) => {
        return Promise.resolve(transformRequirement(data))
    })
}
// 获取企业需求详情
export function getUserRequirement(id) {
    return request.get(`/user/company/requirements/${id}`).then(({ data = {} }) => {
        return Promise.resolve(transformRequirement(data))
    })
}
// 获取后台需求详情
export function getOpRequirement(id) {
    return request.get(`/op/requirements/${id}`).then(({ data = {} }) => {
        return Promise.resolve(transformRequirement(data))
    })
}
// 获取专家需求详情
export function getExpertRequirement(id) {
    return request.get(`/requirements/${id}/draft`).then(({ data = {} }) => {
        return Promise.resolve(transformRequirement(data))
    })
}

// 企业操作 ===============================================

// 发布需求
export function createRequirement(data) {
    const temp = restoreRequirement(data)
    return request.post('/requirements', temp)
}
// 更新需求
export function updateRequirement(id, data) {
    const temp = restoreRequirement(data)
    return request.put(`/requirements/${id}`, temp)
}
// 更新需求时间
export function updateRequirement1(id, data) {
    const temp = restoreRequirement(data)
    return request.put(`/requirements/${id}/duration`, temp)
}
// 关闭需求
export function close(id) {
    return request.post(`/requirements/${id}/close`)
}
// 提交需求
export function submitRequirement(id) {
    return request.post(`/requirements/${id}/submit`)
}
// 发起评估
export function evaluateRequirement(id) {
    return request.post(`/requirements/${id}/evaluate`)
}
// 完成评估
export function finishEvaluate(id, data) {
    return request.post(`/requirements/${id}/finish`, data)
}
// 评价
export function review(id, data) {
    return request.post(`/requirements/${id}/reviews`, data)
}
// 获取我的评价
export function getReview(id) {
    return request.get(`/requirements/${id}/review`)
}

// 管理员操作 ===============================================

// 初审分配专家审核
export function allocateExpert(id, data) {
    return request.post(`/op/requirements/${id}/allocate`, data)
}
// 管理员初审评注
export function putManagerComment(id, data) {
    return request.put(`/op/requirements/${id}/comment`, data)
}
// 发布需求
export function publishOp(id) {
    return request.post(`/op/requirements/${id}/publish`)
}
// 关闭需求
export function closeOp(id) {
    return request.post(`/op/requirements/${id}/close`)
}
// 拒绝需求
export function rejectOp(id) {
    return request.post(`/op/requirements/${id}/reject`)
}
// 更新需求
export function updateOp(id, data) {
    const temp = restoreRequirement(data)
    return request.put(`/op/requirements/${id}`, temp)
}

// 专家操作 =================================================

// 专家审核
export function expertConfirm(id, data) {
    return request.post(`/requirements/${id}/confirm`, data)
}

// 获取响应列表 =======================================================

// 获取管理员需求响应审核列表
export function getOpSupplyList(params) {
    return request.get('/op/supplys', { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformSupply)
        }
        return Promise.resolve(temp)
    })
}
// 获取当前企业响应审核列表
export function getCompanySupplyList(params) {
    return request.get('/user/company/supplys', { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformSupply)
        }
        return Promise.resolve(temp)
    })
}
// 获取专家响应审核列表
export function getExpertSupplyList(params) {
    return request.get('/user/supplys', { params }).then(({ data = {} }) => {
        const rows = data.rows || []
        const temp = {
            count: data.count,
            rows: rows.map(transformSupply)
        }

        return Promise.resolve(temp)
    })
}

// 获取响应详情 ====================================================

// 获取需求详情
export function getSupply(id) {
    return request.get(`/supplys/${id}`).then(({ data = {} }) => {
        return Promise.resolve(transformSupply(data))
    })
}
// 专家获取需求详情
export function getExpertSupply(id) {
    return request.get(`/supplys/${id}/draft`).then(({ data = {} }) => {
        return Promise.resolve(transformSupply(data))
    })
}
// 管理员获取需求详情
export function getOpSupply(id) {
    return request.get(`/op/supplys/${id}`).then(({ data = {} }) => {
        return Promise.resolve(transformSupply(data))
    })
}


// 企业操作 =======================================================

// 创建需求响应
export function createSupply(id, data) {
    const temp = restoreSupply(data)
    return request.post(`/requirements/${id}/supplys`, temp)
}
// 更新需求响应
export function updateSupply(id, data) {
    const temp = restoreSupply(data)
    return request.put(`/supplys/${id}`, temp)
}
// 提交需求响应
export function submitSupply(id, data) {
    const temp = restoreSupply(data)
    return request.post(`/requirements/${id}/submit`, temp)
}
// 删除需求响应
export function delSupply(id) {
    return request.delete(`/supplys/${id}`)
}
export function submitSupplyEvaluate(id) {
    return request.post(`/supplys/${id}/submit`)
}

// 管理员操作  =============================================================

// 拒绝需求响应
export function rejectOpSupply(id) {
    return request.post(`/op/supplys/${id}/reject`)
}

// 需求响应分配专家
export function supplyAllocateExpert(id, data) {
    return request.post(`/op/supplys/${id}/allocate`, data)
}

// 专家操作  =================================================================
export function supplyExpertConfirm(id, data) {
    return request.post(`/supplys/${id}/confirm`, data)
}