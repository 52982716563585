import {
    transformOptions,
    transformAttachment,
    transformStatus,
    restoreOptions,
    transformRegion
} from './_util'

import { transformCompany } from './company'

export function transformRequirement(data) {
   
    const requirement = data || {},
        detail = requirement.Detail || {},
        company = transformCompany({ Detail: requirement.Company }) || {},
        industry = transformOptions(detail.Industries, 'CompanyIndustry') || [],
        scene = transformOptions(detail.Scenes, 'Scene') || [],
        endedTime = new Date(detail.EndedAt).getTime(),
        lastDayTime = Math.ceil((endedTime - new Date().getTime()) / 1000 / (24 * 60 * 60)),
        region = transformRegion(detail.Region) || {},
        str = region.str || {};
    const temp = {
        CompanyID: requirement.CompanyID,
        status: requirement.AuditStatus, // 审核状态
        statusName: transformStatus(requirement.AuditStatus), // 审核状态字符串
        company, // 公司信息
        createdTime: new Date(requirement.CreatedAt).getTime(), // 创建时间戳
        submitedTime: new Date(requirement.SubmittedAt).getTime(), // 提交时间戳
        endedTime, // 结束时间戳
        updatedTime: new Date(requirement.SubmittedAt).getTime(), // 更新时间戳
        startedTime: new Date(detail.StartedAt).getTime(), // 开始时间戳
        lastDayTime,
        lastDay: lastDayTime < 0 ? '已结束' : lastDayTime + '天', // 剩余天数
        id: requirement.ID, // 产品id
        visible: requirement.Visible, // 是否可见
        name: detail.Name, // 需求名称
        budgetMin: detail.BudgetMin, // 投资金额下限
        budgetMax: detail.BudgetMax, // 投资金额上限
        budget: [detail.BudgetMin, detail.BudgetMax],
        budgetStr: detail.BudgetMin === detail.BudgetMax ? `￥${detail.BudgetMin / 10000}万` : `￥${detail.BudgetMin / 10000}-${detail.BudgetMax / 10000}万`,
        industry, // 所属行业
        industryIds: industry.map(item => item.ID || item.Name)[0],
        scene, // 所属行业
        sceneIds: scene.map(item => item.ID || item.Name),
        position: detail.Position, // 联系人职位
        publisher: detail.Publisher, // 需求发布者
        contact: detail.Contact, // 联系人
        desc: detail.Description, // 描述
        email: detail.Email, // 邮箱
        count: requirement.SupplyCount, // 已参与服务商
        ReviewCount: requirement.ReviewCount,
        demands: detail.Demands, // 对服务商的要求
        time: detail.Duration / 24 / 60 / 60, // 需求响应时间
        telephone: detail.Telephone, // 联系人手机
        comment: requirement.Comment, // 平台备注
        feedback: requirement.Feedback, // 审核意见
        evaluate: requirement.EvaluationStatus, // 评估状态
        region: detail.Region, // 区域信息
        IsParticipated: requirement.IsParticipated,
        regionStr: str || '无',
        result: transformAttachment(requirement.Result) || {}, // 附件
    }
    return temp;
}

export function restoreRequirement(data) {
    const BudgetMin = data.budget[0],
        BudgetMax = data.budget[1],
        Scenes = restoreOptions(data.sceneIds, 'Scene'),
        Industries = [restoreOptions(data.industryIds, 'CompanyIndustry')],
        Duration = data.time * 24 * 60 * 60;

    return {
        BudgetMax, // 预算金额上限
        BudgetMin, // 预算金额下限
        Contact: data.contact, // 联系人
        Demands: data.demands,
        Description: data.desc, // 描述
        Duration, // 需求响应周期
        Email: data.email, // 邮箱
        Name: data.name, // 需求名称
        Position: data.position, // 联系人职位
        Telephone: data.telephone, // 手机
        Publisher: data.publisher, // 发布者
        Region: data.region, // 区域
        Scenes,
        Industries,
    };
}