import { transformRequirement } from "./requirement";
import { transformCompany } from './company'
import { transformAttachment, transformStatus } from './_util'

export function transformSupply(data) {
    const supply = data || {},
        detail = supply.Detail || {},
        requirementCompany = supply.RequirementCompany || {},
        solution = detail.Solution || {},
        staff = detail.Staff || {},
        contact = detail.Contact || {},
        company = transformCompany({ Detail: supply.Company }),
        requirement = transformRequirement(
            {
                Company: requirementCompany,
                Detail: {
                    ...supply.Requirement,

                },
                SubmittedAt: supply.SubmittedAt
            }
        ) || {};
    // console.log(requirement);
    const temp = {
        id: supply.ID, // 响应ID
        status: supply.AuditStatus,
        statusName: transformStatus(supply.AuditStatus), // 审核状态字符串
        demands: detail.Demands || [], // 对服务商要求
        people: solution.People, // 投入人员数量
        supplement: solution.Supplement, // 其他补充说明
        introduction: solution.Introduction, // 解决方案描述
        comment: solution.Comment, // 单位意见
        attachments: transformAttachment(solution.Attachments) || [], // 附件
        description: solution.Description, // 资质要求说明
        marketEmail: staff.Email, // 负责人邮箱
        marketName: staff.Name, // 负责人姓名
        marketPhone: staff.Phone, // 负责人电话
        marketPosition: staff.Position, // 负责人职位
        operatorName: contact.Name,
        operatorPhone: contact.Phone,
        operatorEmail: contact.Email,
        operatorPosition: contact.Position,
        feedback: supply.Feedback,
        selected: supply.Selected,
        company,
        requirement: {
            ...requirement,
            supplyCompany: company
        },
        status: supply.AuditStatus,
        selected: supply.Selected,
        evaluate: supply.EvaluationStatus,
        requirementID: supply.RequirementID
    }
    return temp;
}

export function restoreSupply(data) {
    return {
        Demands: data.demands,
        Solution: {
            Attachments: data.attachments,
            Comment: data.comment,
            Description: data.description,
            Introduction: data.introduction,
            People: data.people,
            Supplement: data.supplement
        },
        Staff: {
            Email: data.marketEmail,
            Name: data.marketName,
            Phone: data.marketPhone,
            Position: data.marketPosition
        },
        Contact: {
            Email: data.operatorEmail,
            Name: data.operatorName,
            Phone: data.operatorPhone,
            Position: data.operatorPosition
        }
    }
}