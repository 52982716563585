<template>
  <Link :to="to">
    <div class="border-eee border rounded p-20">
      <div class="relative">
        <div class="absolute right-0 top-0">
          <slot name="status"></slot>
        </div>
        <div>
          <div class="font-bold mb-5 text-xl">
            {{ dataSource.name || "无" }}
          </div>
          <div class="mb-5 text-sm flex justify-between">
            <a-space :size="40">
              <a-space :size="10" style="width:120px">
                <span class="text-999">剩余天数:</span>
                <span>{{ dataSource.lastDay }}</span>
              </a-space>
              <a-space :size="10">
                <span class="text-999">截至日期:</span>
                <span>{{
                  dataSource.endedTime | formatterTime("YYYY-MM-DD")
                }}</span>
              </a-space>
              <a-space :size="10">
                <span class="text-999">应用范围:</span>
                <div style="width: 300px">
                  <gf-tag-group>
                    <gf-tag
                      v-for="(tag, index) in dataSource.scene"
                      :key="index"
                    >
                      {{ tag.Name }}
                    </gf-tag>
                  </gf-tag-group>
                </div>
              </a-space>
            </a-space>
            <div class="font-bold" style="color: #fc8a00">
              {{ dataSource.budgetStr }}
            </div>
          </div>
          <div
            style="border-top: 1px solid #eee"
            class="pt-5 flex justify-between text-999 text-xs"
            v-if="role !== 5"
          >
            <span
              >{{
                dataSource.company.companyName ||
                dataSource.supplyCompany.companyName ||
                "无"
              }}<span
                v-if="
                  type === 'supply' &&
                  (dataSource.company.companyName ||
                    dataSource.supplyCompany.companyName)
                "
                >提交了响应需求表单</span
              ></span
            >
            <span
              >{{
                dataSource.updatedTime | formatterTime("YYYY-MM-DD HH:mm")
              }}
              提交</span
            >
          </div>
        </div>
      </div>
    </div>
  </Link>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    role: {
      type: Number,
    },
    to: {
      type: String,
    },
    type: String,
  },
};
</script>